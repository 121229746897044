import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import LayoutPub from "../../components/LayoutPub"
import { seriesPlaceholder } from "../../util"

export default function PageTemplate({ pageContext: { language, availableLanguages }, data: { mdx } }) {

	return (
		<Layout
			lang={language}
			availableLanguages={availableLanguages}
			mdx={mdx}
			placeholder={seriesPlaceholder[mdx.frontmatter.series]}
			collection={mdx.fields.collection}
		>
			<LayoutPub
				frontmatter={mdx.frontmatter}
				collection={mdx.fields.collection}
				discussants={mdx.discussants}
				body={mdx.body}
				authors={mdx.authors}
				lang={language}
				layout={mdx.frontmatter.layout || (mdx.fields.isFuture && "onecol")}
				showDisclaimer={!mdx.fields.isFuture}
				showTime={mdx.fields.isFuture}
				shareBottom={false} // too short
			/>
		</Layout>
	)

}

export const pageQuery = graphql`
	query SeminarQuery($id: String) {
		mdx(id: { eq: $id }) {
			body
			fields {
				draft
				collection
				shorturl
				originalPath
				isFuture
      }
			frontmatter {
        date
        title
        subtitle
        series
        authors
				discussants
        startTime
        endTime
        location
				endLinks {
					icon
					label
					link
				}
				layout
				showDisclaimer
				video
				cover {
					publicURL
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
						thumbnail: gatsbyImageData(layout: CONSTRAINED, width: 160, height: 160)
					}
				}
				hideCover
				thumbnail {
					childImageSharp {
						thumbnail: gatsbyImageData(layout: CONSTRAINED, width: 160, height: 160)
					}
				}
        topics
        tags
      }
      authors {
        frontmatter {
          memberid
          title
          org
          email
          image {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
          }
					exclude
        }
        fields {
          originalPath
          lang
        }
      }
			discussants {
        frontmatter {
          memberid
          title
          org
          email
          image {
            publicURL
            childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        fields {
          originalPath
          lang
        }
      }
		}
	}
`